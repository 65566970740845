import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { RootState } from 'Store'
import ConfirmationDialog from 'components/Common/ConfirmationDialog/ConfirmationDialog'
import ContextMenu from 'components/Common/ContextMenu/ContextMenu'
import Loader from 'components/Common/Loader'
import SearchBar from 'components/Common/SimpleSearchBar'
import { debouncedInstruction } from 'helpers'
import { isManager, loggedAsManager } from 'helpers/permissions'
import { getUniqueIds } from 'helpers/users'
import { Instruction } from 'objects/types/instructions'
import { ReactElement, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { setRole } from 'reducers/app'
import { resetPagination, setPageNumber } from 'reducers/instruction'
import {
  OperatorState, displayParameters, resetInstructions, setValidation,
} from 'reducers/operator'
import InstructionServices from 'services/InstructionServices'
import RegionServices from 'services/RegionServices'
import UserServices from 'services/UserServices'
import MidiRole from 'services/midiRoleTypes'
import { INSTRUCTION_CONTEXT_MENU } from 'views/admin/HomeTab/InstructionsTab/InstructionsTable/const'
import './InstructionsTable.scss'
import { updateCenteredFeature } from 'reducers/map'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { COLUMNS } from './const'

// TODO mutualize role data table
export default function InstructionsTable(): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    usersInfo, loading,
    validatingInstruction, displayParameters: showParameters,
  } = useSelector((state: RootState) => state.operator) as OperatorState
  const {
    pagination, page, detailsLoading,
    loading: instructionLoading,
  } = useSelector((state: RootState) => state.instruction)
  const { selectedRegion } = useSelector((state: RootState) => state.regions)
  const [search, setSearch] = useState('')
  const [menuInstruction, setInstruction] = useState('')

  const getNext = () => {
    dispatch(setPageNumber(page + 1))
  }

  useEffect(() => {
    if (isManager() && loggedAsManager()) {
      dispatch(setRole(MidiRole.manager))
      dispatch(RegionServices.getAll())
      return
    }
    dispatch(setRole(MidiRole.operator))
  }, [])

  useEffect(() => () => {
    dispatch(resetPagination())
    dispatch(resetInstructions())
  }, [])

  useEffect(() => {
    if (page > 1 && !pagination.results.length) {
      return
    }
    dispatch(InstructionServices.getAll({ page, region: selectedRegion, search }))
  }, [page])

  useEffect(() => {
    const usersId = getUniqueIds(pagination.results, usersInfo)
    if (usersId.length) {
      dispatch(UserServices.getUsersListInfo((usersId)))
    }
  }, [pagination])

  const handleInstructionClick = (instruction: Instruction) => {
    dispatch(updateCenteredFeature(undefined))
    if (showParameters) {
      dispatch(displayParameters())
    }

    PanelNavigator.reset()
    dispatch(InstructionServices.getDetails(instruction.id))
  }

  const onSearch = (s: string) => {
    setSearch(s)
    if (page === 1) {
      debouncedInstruction(page, selectedRegion, s)
    } else dispatch(resetPagination())
  }

  const closeValidationPopup = () => {
    setInstruction('')
    dispatch(setValidation(''))
  }

  const dialogAction = () => {
    if (menuInstruction) {
      dispatch(InstructionServices.delete(menuInstruction))
    } else {
      dispatch(InstructionServices.validate(validatingInstruction))
    }
  }

  return (
    <div className="instructions-dashboard-user">
      <div className="search">
        {!detailsLoading && (
          <SearchBar
            onChange={onSearch}
            value={search}
          />
        )}
      </div>

      {(loading && page === 1) || instructionLoading ? (
        <div className="h-100 w-100">
          <Loader />
        </div>
      ) : (
        <TableContainer id="wrapper" className={loggedAsManager() ? 'manager' : ''}>
          <InfiniteScroll
            dataLength={pagination.results.length}
            next={getNext}
            hasMore={pagination.count > pagination.results.length}
            loader={<Loader />}
            scrollableTarget="wrapper"
            style={{ overflow: 'hidden' }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {COLUMNS.map(column => (
                    <TableCell key={column.title}>{t(column.title)}</TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {pagination.results.map((instruction: Instruction) => (
                  <TableRow
                    key={instruction.id}
                    hover
                    className="instruction-row"
                  >
                    {COLUMNS.map(column => (
                      <TableCell
                        key={column.title}
                        onClick={() => { handleInstructionClick(instruction) }}
                      >
                        {column.formatter(instruction[column.propertyName], usersInfo)}
                      </TableCell>
                    ))}
                    <TableCell>
                      <ContextMenu menuItems={INSTRUCTION_CONTEXT_MENU(instruction, setInstruction)} />
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </TableContainer>
      )}

      <ConfirmationDialog
        title={menuInstruction ? t('Instruction.deletion.title') : t('Instruction.validation.action')}
        content={menuInstruction ? t('Instruction.deletion.warning') : t('Instruction.validation.validateAll')}
        toggle={closeValidationPopup}
        open={!!menuInstruction || !!validatingInstruction}
        actionToConfirm={dialogAction}
      />
    </div>
  )
}
