import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, ReactNode } from 'react'

type Props = {
  open: boolean;
  toggle: () => void;
  actionToConfirm: () => void;
  actionToCancel?: () => void;
  title: ReactNode;
  content: ReactNode;
} & typeof defaultProps

const defaultProps = {
  actionToCancel: {/* */},
}

export default function ConfirmationDialog({
  open, toggle, actionToConfirm, title, content, actionToCancel,
}: Props): ReactElement {
  const { t } = useTranslation()

  const confirmAction = () => {
    toggle()
    actionToConfirm()
  }

  const cancelAction = () => {
    if (typeof actionToCancel === 'function') {
      actionToCancel()
    }

    toggle()
  }

  return (
    <Dialog open={open}>

      <DialogTitle>
        {title}
      </DialogTitle>

      <DialogContent>
        {content}
      </DialogContent>

      <DialogActions>
        <Button onClick={cancelAction}>{t('Common.button.cancel')}</Button>
        <Button onClick={confirmAction}>{t('Common.button.confirm')}</Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialog.defaultProps = defaultProps
