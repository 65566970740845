/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get,
  patch, post, getAuthConfig,
  RequestManager,
} from '@osrdata/app_core/dist/requests'
import { AnyAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ExportStatus, Instruction, InstructionDetails } from 'objects/types/instructions'
import { MIDI_URI } from 'objects/uri'
import { PaginatedInstructions, RemoveItem } from 'reducers/types'
import URI from 'services/uri'
import { ThunkApiConfig } from 'types'
import { Dag } from 'objects/types/common'
import { AsyncTask } from 'objects/types'
import {
  addItems,
  deleteItem,
  getItemsDetailsList,
  getItemsInBbox,
  updateItems,
} from './items'

type PageSearch = {
  page: number;
  search?: string;
  region?: string;
}
const rq = new RequestManager()

export const fetchInstructionDetails = async (id: string): Promise<InstructionDetails> => {
  const response: InstructionDetails = await get(`/${MIDI_URI}/${URI.instructions}/${id}`)
  return response
}

const getAll = createAsyncThunk<PaginatedInstructions, PageSearch, ThunkApiConfig>(
  'instruction/getAll',
  async (params: PageSearch, thunkApi) => {
    rq.abort()
    try {
      const queryParams = params.search ? params : { page: params.page }
      const regionParam = params.region === 'all' || params.region === undefined
        ? {} : { region_trigram: params.region }
      const response = await rq.get(`/${MIDI_URI}/${URI.instructions}/`, { ...queryParams, ...regionParam })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getDetails = createAsyncThunk<InstructionDetails, string, ThunkApiConfig>(
  'instruction/getDetails',
  async (id: string, thunkApi) => {
    try {
      const response: InstructionDetails = await get(`/${MIDI_URI}/${URI.instructions}/${id}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const addUsers = createAsyncThunk<Instruction, Partial<Instruction>, ThunkApiConfig>(
  'instruction/addUsers',
  async (instruction, thunkApi) => {
    try {
      const response: Instruction = await patch(
        `/${MIDI_URI}/${URI.instructions}/${instruction.id}`,
        { users: instruction.users },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<Instruction, Partial<Instruction>, ThunkApiConfig>(
  'instruction/create',
  async (newInstruction, thunkApi) => {
    try {
      const response: Instruction = await post(`/${MIDI_URI}/${URI.instructions}/`, newInstruction)
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const UPDATABLE_FIELDS: Array<keyof Instruction> = [
  'name', 'reference', 'version', 'versionDate', 'applicationDate', 'regions',
]

const update = createAsyncThunk<AsyncTask | Instruction, Partial<Instruction> & {async?: boolean}, ThunkApiConfig>(
  'instruction/update',
  async (params, thunkApi) => {
    const { async, ...newInstruction } = params
    let filteredInstruction: Partial<Instruction> = {
      id: newInstruction.id,
    }
    filteredInstruction = Object.keys(newInstruction)
      .filter(key => UPDATABLE_FIELDS.includes(key as keyof Instruction))
      .reduce((obj, key) => {
        const value = newInstruction[key as keyof Instruction]
        if (value !== undefined) {
          return {
            ...obj,
            [key as keyof Instruction]: value,
          }
        }
        return obj
      }, filteredInstruction)
    try {
      const response = await patch(
        `/${MIDI_URI}/${URI.instructions}/${newInstruction.id}`,
        filteredInstruction, { params: { async } },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'instruction/delete',
  async (id, thunkApi) => {
    try {
      await deleteRequest(`/${MIDI_URI}/${URI.instructions}/${id}`)
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const exportDispatchedData = createAsyncThunk<{gaiaExportResult: string}, string, ThunkApiConfig>(
  'instruction/exportDispatchedData',
  async (id: string, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/${URI.instructions}/${id}/xlsx/`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getExportStatus = createAsyncThunk<ExportStatus, string, ThunkApiConfig>(
  'instruction/getExportStatus',
  async (id: string, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/gaia-export-results/${id}/`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getExportFile = createAsyncThunk<ArrayBuffer, string, ThunkApiConfig>(
  'instruction/getExportFile',
  async (id, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/gaia-export-results/${id}/download/`, undefined, undefined, {
        ...getAuthConfig(),
        headers: {
          ...getAuthConfig().headers,
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'arraybuffer',
      })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<Instruction, string, ThunkApiConfig>(
  'instruction/validate',
  async (id, thunkApi) => {
    try {
      const response: Instruction = await post(`/${MIDI_URI}/${URI.instructions}/${id}/validate`, {})
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const removeItem = createAsyncThunk<string, RemoveItem, ThunkApiConfig>(
  'instruction/removeItem',
  async (params, thunkApi) => {
    try {
      const { instructionId, itemId } = params
      const response = await deleteRequest(`/${MIDI_URI}/${URI.instructions}/${instructionId}/items/${itemId}`)
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getObjectInstructions = createAsyncThunk<Instruction[], string, ThunkApiConfig>(
  'instruction/getObjectsInstructions',
  async (id, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/common/${id}/instructions`)
      if (!response.results.length) {
        const instructions = await thunkApi.dispatch(getAll({ page: 1 }) as unknown as AnyAction)
        return instructions.payload.results as Instruction[]
      }
      return response.results
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getDagStatus = createAsyncThunk<Dag, string, ThunkApiConfig>(
  'instruction/getDagStatus',
  async (name, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/common/dags/${name}`)
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const InstructionServices = {
  getAll,
  getDetails,
  create,
  update,
  delete: deleteObject,
  addItems,
  updateItems,
  addUsers,
  getItemsInBbox,
  getItemsDetailsList,
  deleteItem,
  exportDispatchedData,
  validate,
  removeItem,
  getObjectInstructions,
  getExportStatus,
  getExportFile,
  getDagStatus,
}

export default InstructionServices
