import {
  ADMIN_HOMEPATH, MANAGER_HOMEPATH, OPERATOR_HOMEPATH, SUPERVISOR_HOMEPATH,
} from 'Routes'
import { store } from 'Store'
import { Tab } from 'components/Common/TabsMenu/TabsMenu'
import ButtonStatus from 'components/Common/buttonStatus'
import BrowseHistory from 'customHistory'
import { loggedAsAdmin, loggedAsManager, loggedAsSupervisor } from 'helpers/permissions'
import ElectricalElementDetails from 'objects/ElectricalElements/ElectricalElementDetails'
import ElectricalProtectionGroupDetails from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupDetails'
import FeederDetails from 'objects/Feeders/FeederDetails'
import IsolatorDetails from 'objects/Isolators/IsolatorDetails'
import SectorDetails from 'objects/Sectors/SectorDetails'
import SignalDetails from 'objects/Signals/SignalDetails'
import SubSectorDetails from 'objects/SubSectors/SubSectorDetails'
import TrackNodeDetails from 'objects/TrackNodes/TrackNodeDetails'
import TrackProtectionGroupDetails from 'objects/TrackProtectionGroups/TrackProtectionGroupDetails'
import TrackProtectionDetails from 'objects/TrackProtections/TrackProtectionDetails'
import TrackSectionDetails from 'objects/TrackSections/TrackSectionDetails'
import { MidiObject, ObjectHistory } from 'objects/types'
import { ObjectKind } from 'objects/types/const'
import { ExtremityObject } from 'objects/types/protections'
import { ReactElement } from 'react'
import MidiRole from 'services/midiRoleTypes'
import TunnelDetails from 'objects/Tunnels/TunnelDetails'
import History from './History'

export enum TabKeys {
  attributes = 'attributes',
  linkedObjects = 'linkedObjects',
  history = 'history',
  notes = 'notes',
}

export enum ValidationOperation {
  refuse = 'refuse',
  verify = 'verify',
  validate = 'validate',
  undelete = 'undelete',
}

export type ValidationParams = {
  id: string;
  operation: string;
};

export const OPERATOR_VALIDATE_BUTTON_TEXT = {
  [ButtonStatus.Base]: 'Validation.button.ready',
  [ButtonStatus.Disabled]: 'Validation.button.ready',
  [ButtonStatus.Loading]: 'Validation.button.loading',
  [ButtonStatus.Success]: 'Validation.button.success',
}

export const SUPERVISOR_VALIDATE_BUTTON_TEXT = {
  [ButtonStatus.Base]: 'Validation.button.validate',
  [ButtonStatus.Disabled]: 'Validation.button.disabled',
  [ButtonStatus.Loading]: 'Validation.button.loading',
  [ButtonStatus.Success]: 'Validation.button.success',
}

export const SUPERVISOR_VALIDATE_DELETION_BUTTON_TEXT = {
  [ButtonStatus.Base]: 'Validation.button.validateDeletion',
  [ButtonStatus.Disabled]: 'Validation.button.disabled',
  [ButtonStatus.Loading]: 'Validation.button.loading',
  [ButtonStatus.Success]: 'Validation.button.success',
}

export const SUPERVISOR_REFUSE_DELETION_BUTTON_TEXT = {
  [ButtonStatus.Base]: 'Validation.button.refuseDeletion',
  [ButtonStatus.Disabled]: 'Validation.button.disabled',
  [ButtonStatus.Loading]: 'Validation.button.loading',
  [ButtonStatus.Success]: 'Validation.button.success',
}

export const REFUSE_BUTTON_TEXT = {
  [ButtonStatus.Base]: 'Validation.button.refuse',
  [ButtonStatus.Disabled]: 'Validation.button.base',
  [ButtonStatus.Loading]: 'Validation.button.loading',
  [ButtonStatus.Success]: 'Validation.button.success',
}

export const getValidateButtonText = (
  role?: MidiRole,
  isDeletion?: boolean,
): typeof OPERATOR_VALIDATE_BUTTON_TEXT => {
  switch (role) {
    case MidiRole.manager:
    case MidiRole.operator:
      return OPERATOR_VALIDATE_BUTTON_TEXT
    case MidiRole.supervisor:
      if (isDeletion) return SUPERVISOR_VALIDATE_DELETION_BUTTON_TEXT
      return SUPERVISOR_VALIDATE_BUTTON_TEXT
    default:
      return REFUSE_BUTTON_TEXT
  }
}

export interface DetailPanelTab extends Tab {
  key: TabKeys;
  value: string;
  label: string;
  hideFor?: ObjectKind[];
}

export const DETAILS_PANEL_TABS: DetailPanelTab[] = [
  {
    key: TabKeys.attributes,
    value: '1',
    label: 'Details.tabs.attributes',
  },
  {
    key: TabKeys.history,
    value: '3',
    label: 'Details.tabs.history',
  },
]

export const shouldDisplayTab = (
  tab: DetailPanelTab,
  kind: ObjectKind,
): boolean => !tab.hideFor || (tab.hideFor && !tab.hideFor.includes(kind))

type TabContentOfKindType = { [k: string]: { [k: string]: (arg: MidiObject) => ReactElement } };

export const TabContentOfKind: TabContentOfKindType = {
  [ObjectKind.ElectricalElement]: {
    [TabKeys.attributes]: (item: MidiObject) => <ElectricalElementDetails object={item} />,
    [TabKeys.history]: (item: MidiObject) => <History object={item} />,
  },
  [ObjectKind.ElectricalProtectionGroup]: {
    [TabKeys.attributes]: (item: MidiObject) => <ElectricalProtectionGroupDetails object={item} />,
    [TabKeys.history]: (item: MidiObject) => <History object={item} />,
  },
  [ObjectKind.Feeder]: {
    [TabKeys.attributes]: (item: MidiObject) => <FeederDetails object={item} />,
    [TabKeys.history]: (item: MidiObject) => <History object={item} />,
  },
  [ObjectKind.Isolator]: {
    [TabKeys.attributes]: (item: MidiObject) => <IsolatorDetails object={item} />,
    [TabKeys.history]: (item: MidiObject) => <History object={item} />,
  },
  [ObjectKind.Sector]: {
    [TabKeys.attributes]: (item: MidiObject) => <SectorDetails object={item} />,
    [TabKeys.history]: (item: MidiObject) => <History object={item} />,
  },
  [ObjectKind.Signal]: {
    [TabKeys.attributes]: (item: MidiObject) => <SignalDetails object={item} />,
    [TabKeys.history]: (item: MidiObject) => <History object={item} />,
  },
  [ObjectKind.SubSector]: {
    [TabKeys.attributes]: (item: MidiObject) => <SubSectorDetails object={item} />,
    [TabKeys.history]: (item: MidiObject) => <History object={item} />,
  },
  [ObjectKind.TrackNode]: {
    [TabKeys.attributes]: (item: MidiObject) => <TrackNodeDetails object={item} />,
    [TabKeys.history]: (item: MidiObject) => <History object={item} />,
  },
  [ObjectKind.TrackProtection]: {
    [TabKeys.attributes]: (item: MidiObject) => <TrackProtectionDetails object={item} />,
    [TabKeys.history]: (item: MidiObject) => <History object={item} />,
  },
  [ObjectKind.TrackProtectionGroup]: {
    [TabKeys.attributes]: (item: MidiObject) => <TrackProtectionGroupDetails object={item} />,
    [TabKeys.history]: (item: MidiObject) => <History object={item} />,
  },
  [ObjectKind.TrackSection]: {
    [TabKeys.attributes]: (item: MidiObject) => <TrackSectionDetails object={item} />,
    [TabKeys.history]: (item: MidiObject) => <History object={item} />,
  },
  [ObjectKind.Tunnel]: {
    [TabKeys.attributes]: (item: MidiObject) => <TunnelDetails object={item} />,
    [TabKeys.history]: (item: MidiObject) => <History object={item} />,
  },
}

export const removeParamsFromUrl = (): void => {
  let url = OPERATOR_HOMEPATH
  if (loggedAsManager()) {
    url = MANAGER_HOMEPATH
  }
  if (loggedAsSupervisor()) {
    url = SUPERVISOR_HOMEPATH
  }
  if (loggedAsAdmin()) {
    url = ADMIN_HOMEPATH
  }
  BrowseHistory.push(url)
}

export const canEditObject = () => !loggedAsSupervisor()
  && !store.getState().detailsPanel.displayHistory && !store.getState().detailsPanel.objectToMerge.id

export const formatActivityDates = (obj: ObjectHistory | ExtremityObject) => `${(
  obj.activityStartDate && new Date(obj.activityStartDate).toLocaleDateString('fr-FR')) || '/'} 
    -
     ${(obj.activityEndDate && new Date(obj.activityEndDate).toLocaleDateString('fr-FR')) || '/'}`
